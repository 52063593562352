import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import "./App.css";
import { sections, Phase, PhaseValue, initialValues } from "./types";
import arrowUpIcon from "./assets/w@3x.png";
import { useCallback } from "react";
import firebase from "firebase/app";
import "firebase/functions";
import { Rank } from "./Rank";
import { isMobile, isTablet } from "react-device-detect";

const styles: { [key: string]: React.CSSProperties } = {
  logo: {
    maxWidth: 256,
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 16,
  },
  head: {
    width: "100%",
    height: 240,
    backgroundPosition: "center",
    backgroundSize: "100% auto",
    backgroundRepeat: "no-repeat",
    maxWidth: 2000,
    margin: "0 auto",
  },
  headMobile: {
    height: 200,
    backgroundSize: "auto 100%",
  },
  headerImg: {
    objectFit: "cover",
    width: "100%",
    height: 200,
  },
  lead: {
    marginTop: 20,
    marginBottom: 40,
    fontSize: 14,
    lineHeight: 1.57,
  },
  leadHeading: {
    fontSize: 16,
    fontWeight: 600,
  },
  main: {
    paddingBottom: 250,
  },
  highlightedSection: {
    backgroundColor: "#ebeef4",
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 600,
  },
  questionTitle: {
    fontSize: 14,
    fontWeight: 600,
  },
  requiredMark: {
    display: "inline-block",
    padding: "2px 4px",
    backgroundColor: "#ac6161",
    color: "#ffffff",
    fontSize: 12,
    marginLeft: 10,
  },
  button: {
    fontSize: 16,
  },
  submitButton: {
    backgroundColor: "#2e4489",
    borderColor: "#2e4489",
  },
  scrollTopBar: {
    backgroundColor: "#777777",
    position: "absolute",
    bottom: 80,
    width: "100%",
    textAlign: "center",
    color: "#ffffff",
    padding: "10px 0",
    cursor: "pointer",
  },
  footer: {
    padding: 32,
    backgroundColor: "#333333",
    fontWeight: 400,
    color: "#55555",
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: 80,
  },
  hpLink: {
    color: "#888",
    textDecoration: "underline",
  },
  arrowUpIcon: {
    width: 20,
    height: 20,
    marginTop: -3,
    marginRight: 5,
  },
  error: {
    color: "#ae2e2e",
    fontWeight: 600,
    fontSize: 14,
    marginTop: 10,
  },
  bold: {
    fontWeight: 600,
  },
};

const RequiredMark = () => <span style={styles.requiredMark}>必須</span>;

const errorMsgRequired = "選択してください";

type AppFormProps = {
  values: { [k: string]: string };
  setValues: React.Dispatch<
    React.SetStateAction<{
      [k: string]: string;
    }>
  >;
  setRank: (rank: string | undefined) => void;
  setShowScrollTop: (showScrollTop: boolean) => void;
};

const AppForm = ({
  setShowScrollTop,
  setRank,
  values,
  setValues,
}: AppFormProps) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(false);
  const showRadioButtons = useMemo(
    () => !!values.market && !!values.phase,
    [values]
  );

  useEffect(() => {
    setShowScrollTop(showRadioButtons);
  }, [showRadioButtons, setShowScrollTop]);

  useEffect(() => {
    if (!errors || Object.keys(errors).length === 0) {
      return;
    }
    const els = document.querySelectorAll("input.is-invalid");
    if (els?.length > 0) {
      const el = els[0].parentElement?.parentElement;
      el?.scrollIntoView({ behavior: "smooth" });
    }
  }, [errors]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChange = useCallback(
    (key: string, value: string) => {
      setValues({
        ...values,
        [key]: value,
      });
      if (value) {
        delete errors[key];
        setErrors(errors);
      } else {
        setErrors({
          ...errors,
          [key]: errorMsgRequired,
        });
      }
    },
    [values, errors, setValues]
  );
  const onSubmit = useCallback(async () => {
    for (let id in initialValues) {
      if (!values[id]) {
        errors[id] = errorMsgRequired;
      }
    }
    if (Object.keys(errors).length !== 0) {
      setErrors({ ...errors });
      return;
    }

    try {
      setLoading(true);
      const functions = firebase.app().functions("asia-northeast1");
      //functions.useEmulator("localhost", 5001);
      const res = await functions.httpsCallable("getRank")(values);
      setRank(res.data as string);
    } catch (e) {
      alert("エラーが発生しました。");
      setLoading(false);
    }
  }, [values, errors, setRank]);

  return (
    <Form>
      <div className="lead" style={styles.lead}>
        <p style={styles.leadHeading}>
          IPO協会 轟 一般社団法人　IPO Todoroki Score サンプルページです。
        </p>
        IPO Todoroki
        Scoreは、上場を目指す経営者に向け、上場確度の定量化を企図して開発されました。
        本ページは日本市場版サンプルとして、質問項目を絞り、スコアをAからEのレンジ表記します。
        IPO協会轟へ入会希望される経営者の方は、サンプルスコアと共にお問合わせフォームよりご連絡下さい。
        <br />
        (なお、協会入会には一定の審査があります)
      </div>
      <p className="mt-4" style={styles.bold}>
        取引市場、現在属する期を選び、選択肢を選んで下さい。スコアのレンジが表示されます。
      </p>
      {sections[0].questions.map((q) => (
        <Form.Group controlId={q.id} key={q.id} className="mb-4">
          <Form.Label style={styles.bold}>
            {q.label}
            <RequiredMark />
          </Form.Label>
          <Form.Control
            as="select"
            custom
            name={q.id}
            value={values[q.id]}
            disabled={showRadioButtons}
            onChange={(e) => {
              onChange(q.id, e.target.value);
            }}
            isInvalid={!!errors[q.id]}
          >
            <option value="">選択してください</option>
            {q.options.map((o) => (
              <option value={o.value} key={o.value}>
                {o.label(Phase.n)}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid" style={styles.error}>
            {errors[q.id]}
          </Form.Control.Feedback>
        </Form.Group>
      ))}
      {showRadioButtons && (
        <>
          {sections.slice(1).map((s, sIdx) => (
            <div
              className="mt-2 pt-3 pb-1 px-3"
              style={sIdx % 2 !== 0 ? styles.highlightedSection : undefined}
              key={sIdx}
            >
              <div className="mb-3" style={styles.sectionTitle}>
                {sIdx + 1}. {s.label}
              </div>
              {s.questions.map((q, qIdx) => (
                <Form.Group controlId={q.id} className="mt-3 mb-4" key={qIdx}>
                  <Form.Label className="mb-3" style={styles.questionTitle}>
                    {sIdx + 1}-{qIdx + 1}. {q.label}
                    <RequiredMark />
                  </Form.Label>
                  {q.options
                    .sort((a, b) => b.value.localeCompare(a.value))
                    .map((o) => (
                      <Form.Check className="mb-3" key={`${q.id}-${o.value}`}>
                        <Form.Check.Input
                          id={`${q.id}-${o.value}`}
                          type="radio"
                          value={o.value}
                          name={q.id}
                          isInvalid={!!errors[q.id]}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            onChange(q.id, o.value);
                          }}
                          defaultChecked={values[q.id] === o.value}
                        />
                        <Form.Check.Label htmlFor={`${q.id}-${o.value}`}>
                          {o.label(values.phase as PhaseValue)}
                        </Form.Check.Label>
                      </Form.Check>
                    ))}
                  {errors[q.id] && (
                    <div
                      className="invalid-feedback"
                      style={{ ...styles.error, display: "block" }}
                    >
                      {errors[q.id]}
                    </div>
                  )}
                </Form.Group>
              ))}
            </div>
          ))}
          <div className="row mt-4">
            <div className="col">
              <button
                type="button"
                className="btn btn-outline-dark btn-lg w-100"
                style={styles.button}
                onClick={() => {
                  setValues(initialValues);
                  setErrors({});
                }}
              >
                クリア
              </button>
            </div>
            <div className="col">
              {!loading && (
                <button
                  type="button"
                  style={{ ...styles.button, ...styles.submitButton }}
                  className="btn btn-primary btn-lg w-100"
                  onClick={onSubmit}
                >
                  判定する
                </button>
              )}
              {loading && (
                <button
                  type="button"
                  style={{ ...styles.button, ...styles.submitButton }}
                  className="btn btn-primary btn-lg w-100"
                  disabled={true}
                >
                  <span
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  判定中...
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </Form>
  );
};

const App = () => {
  const [values, setValues] = useState(initialValues);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [rank, setRank] = useState<string | undefined>();
  const [headerImgFile, setHeaderImgFile] = useState<string>();
  const [headerImg, setHeaderImg] = useState<string>();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    setHeaderImgFile(width > 768 ? "768_1440.png" : "0_767.png");
  }, [width]);

  useEffect(() => {
    if (!headerImgFile) {
      return;
    }
    import(`./assets/${headerImgFile}`).then((img) => {
      setHeaderImg(img.default);
    });
  }, [headerImgFile]);

  return (
    <>
      <div className="container">
        <img
          src={process.env.PUBLIC_URL + "/logo.png"}
          style={styles.logo}
          alt="IPO Todoroki Score"
        />
      </div>
      {rank ? (
        <hr style={{ margin: 0 }} />
      ) : (
        <div
          style={{
            ...styles.head,
            ...(isMobile && !isTablet ? styles.headMobile : {}),
            backgroundImage: `url(${headerImg})`,
          }}
        />
      )}
      <div className="container">
        <main style={styles.main}>
          <div className="row justify-content-md-center">
            <div className="col-lg-9">
              {rank ? (
                <Rank rank={rank} setRank={setRank} />
              ) : (
                <AppForm
                  setShowScrollTop={setShowScrollTop}
                  setRank={setRank}
                  values={values}
                  setValues={setValues}
                />
              )}
            </div>
          </div>
        </main>
      </div>
      {showScrollTop && (
        <div
          style={styles.scrollTopBar}
          onClick={() =>
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
          }
        >
          <img src={arrowUpIcon} style={styles.arrowUpIcon} alt="" />
          トップにもどる
        </div>
      )}
      <footer
        className="text-muted text-center text-small"
        style={styles.footer}
      >
        <p className="mb-1">
          © {new Date().getFullYear()}{" "}
          <a
            href="https://www.todorok-evolution.jp/"
            target="_blank"
            rel="noreferrer"
            style={styles.hpLink}
          >
            IPO協会 轟 一般社団法人
          </a>
        </p>
      </footer>
    </>
  );
};

export default App;
