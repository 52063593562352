export const Phase = {
    n: "n", // N期(上場申請期)
    n1: "n1", // N-1期(上場直前期)
    n2: "n2", // N-2期(上場直前々期)
    n3: "n3" // N-3期以前
} as const;

export type PhaseValue = keyof typeof Phase;

export const Market = {
    tseGrowth: "tseGrowth", 
    nseNext: "nseNext", 
    sseAmbitious: "sseAmbitious", 
    fseQBoard: "fseQBoard", 
    tseStandard: "tseStandard", 
    nseMain: "nseMain", 
    sseMain: "sseMain", 
    fseMain: "fseMain", 
    tsePrime: "tsePrime", 
    nsePremier: "nsePremier"
} as const;

export type MarketValue = keyof typeof Market;

export type Option = {
    label: (phase: PhaseValue) => string;
    value: string;
};

export type Question = {
    id: string;
    label: string;
    options: Option[];
    scoreIndex: number;
};

export type Section = {
    label: string;
    questions: Question[];
};

export const questionMarket: Question = {
    id: "market",
    scoreIndex: -1,
    label: "取引市場",
    options: [
        { label: (_) => "東証グロース", value: Market.tseGrowth },
        { label: (_) => "名証ネクスト", value: Market.nseNext },
        { label: (_) => "札証アンビシャス", value: Market.sseAmbitious },
        { label: (_) => "福証Q-board", value: Market.fseQBoard },
        { label: (_) => "東証スタンダード", value: Market.tseStandard },
        { label: (_) => "名証メイン", value: Market.nseMain },
        { label: (_) => "札証本則", value: Market.sseMain },
        { label: (_) => "福証本則", value: Market.fseMain },
        { label: (_) => "東証プライム", value: Market.tsePrime },
        { label: (_) => "名証プレミア", value: Market.nsePremier },
    ],
};

export const questionPhase: Question = {
    id: "phase",
    scoreIndex: -1,
    label: "現在属する期",
    options: [
        { label: (_) => "N期(上場申請期)", value: Phase.n },
        { label: (_) => "N-1期(上場直前期)", value: Phase.n1 },
        { label: (_) => "N-2期(上場直前々期)", value: Phase.n2 },
        { label: (_) => "N-3期以前", value: Phase.n3 },
    ],
};

export const questionLatestPerformance: Question =             {
    id: "latestPerformance",
    scoreIndex: 0,
    label: "直近2期間(年度)で",
    options: [
        {
            label: (phase) => {
                switch (phase) {
                    case Phase.n:
                    case Phase.n1:
                        return "直近年30%超増収、経常利益2期合計が3億円以下";
                    case Phase.n2:
                        return "直近年30%超増収、経常利益1億円超計上した期は無い";
                    case Phase.n3:
                        return "売上高3億円以上、増収(売上高)";
                }
            },
            value: "1",
        },
        {
            label: (phase) => {
                switch (phase) {
                    case Phase.n:
                    case Phase.n1:
                        return "直近増収で、経常利益の2期合計が3億円超";
                    case Phase.n2:
                        return "経常利益1億円超した期が有り、直近増収";
                    case Phase.n3:
                        return "売上高3億円以上、直近年30%超増収";
                }
            },
            value: "2",
        },
        { label: (_) => "上記以外", value: "0" },
    ],
};

export const questionLocalReputation: Question = {
    id: "localReputation",
    scoreIndex: 1,
    label: "本社や拠点がある地方(関東/東海/北海道/九州等)では",
    options: [
        { label: (_) => "不本意ながら評判は良くない", value: "0" },
        { label: (_) => "悪い評判は特にない", value: "1" },
        { label: (_) => "金融機関関係者なら社名は知っており、評判が良い方だと思う", value: "2" },
    ],
};

export const questionDirectors: Question = {
    id: "directors",
    scoreIndex: 2,
    label: "取締役全員に共通するのは直近12ヶ月",
    options: [
        { label: (_) => "取締役会参加率90%超", value: "1" },
        { label: (_) => "取締役会参加率100%", value: "2" },
        { label: (_) => "上記以外", value: "0" },
    ],
};

export const questionAuditors: Question = {
    id: "auditors",
    scoreIndex: 3,
    label: "監査役全員に共通するのは",
    options: [
        { label: (_) => "取締役会報告資料の経営指標とKPIをプレゼンできる", value: "1" },
        { label: (_) => "機関投資家と1on1できる", value: "2" },
        { label: (_) => "上記以外", value: "0" },
    ],
};

export const questionHr: Question = {
    id: "hr",
    scoreIndex: 4,
    label: "公平な評価制度設計を",
    options: [
        { label: (_) => "これから検討する", value: "0" },
        { label: (_) => "指示すれば運用できる人材がいる", value: "1" },
        { label: (_) => "任せられる責任者がいる", value: "2" },
    ],
};

export const questionRecruit: Question = {
    id: "recruit",
    scoreIndex: 5,
    label: "事業計画上の採用計画&雇用以外の人的資源確保を",
    options: [
        { label: (_) => "これから検討する", value: "0" },
        { label: (_) => "指示すれば達成できそうな担当者がいる", value: "1" },
        { label: (_) => "任せられる責任者がいる", value: "2" },
    ],
};

export const questionInformationSystem: Question = {
    id: "informationSystem",
    scoreIndex: 6,
    label: "上場後の情報システム機能について",
    options: [
        { label: (_) => "エンジニアや総務で何とかなると思う", value: "0" },
        { label: (_) => "全社統制を理解する専任担当者がいる", value: "1" },
        { label: (_) => "任せられる責任者がいる", value: "2" },
    ],
};

export const questionDeviceManagement: Question = {
    id: "deviceManagement",
    scoreIndex: 7,
    label: "PCや貸与携帯等、端末管理を行い、",
    options: [
        { label: (_) => "紛失しないよう指導するのみである", value: "0" },
        { label: (_) => "紛失時は利用制限を掛ける設定が無いため、連絡あり次第、各種システムのPW変更等を行う体制がある", value: "1" },
        { label: (_) => "紛失時はActive Directoryなどで利用制限を掛けられる", value: "2" },
    ],
};

export const questionPenetrationTest: Question = {
    id: "penetrationTest",
    scoreIndex: 8,
    label: "自社のネットワークやサービスに対して外部侵入テストを",
    options: [
        { label: (_) => "行っていないが、今後検討予定である", value: "0" },
        { label: (_) => "毎年行っている", value: "1" },
        { label: (_) => "実施結果を経営会議やリスク委員会など、全社の機関で報告している", value: "2" },
    ],
};

export const questionCustodyOfRecords: Question = {
    id: "custodyOfRecords",
    scoreIndex: 9,
    label: "機密情報・個人情報・保管の定めがある文書に対し",
    options: [
        { label: (_) => "規程上、適切な運用を定めている", value: "0" },
        { label: (_) => "規程通りの運用となっているか、極秘、Confidential、保管年数が明示的に定められているか全て年1度以上確認し、証跡を残している", value: "1" },
        { label: (_) => "規程通りの運用を年1度以上確認し、監査の対象としている", value: "2" },
    ],
};

export const questionLaws: Question = {
    id: "laws",
    scoreIndex: 10,
    label: "会社にとって影響が大きな法律３つを",
    options: [
        { label: (_) => "言える", value: "1" },
        { label: (_) => "法改正の動向含め投資家に資料無しでも説明できる", value: "2" },
        { label: (_) => "上記以外", value: "0" },
    ],
};

export const questionTerms: Question = {
    id: "terms",
    scoreIndex: 11,
    label: "新規プロダクト公表時に利用規約等を",
    options: [
        { label: (_) => "状況に応じて法務に照会", value: "0" },
        { label: (_) => "法務に事前共有", value: "1" },
        { label: (_) => "法務＋経理や内部監査などの部門と事前共有", value: "2" },
    ],
};

export const questionWorkingHoursDivergence: Question = {
    id: "workingHoursDivergence",
    scoreIndex: 12,
    label: "実質労働時間と打刻時間の差が",
    options: [
        { label: (_) => "大きくは無いが、これから把握予定", value: "0" },
        { label: (_) => "30分以内", value: "1" },
        { label: (_) => "15分以内", value: "2" },
    ],
};

export const questionPcLogs: Question = {
id: "pcLogs",
scoreIndex: 13,
label: "PCログを取得し、実質労働時刻との",
options: [
    { label: (_) => "PCログ取得していない", value: "0" },
    { label: (_) => "差を全件分析している", value: "1" },
    { label: (_) => "差は15分以上無い", value: "2" },
],
};

export const questionNightTime: Question = {
    id: "nightTime",
    scoreIndex: 14,
    label: "深夜時間帯(22-5時)にメールやslack等の反応がある従業員が",
    options: [
        { label: (_) => "20%以上", value: "0" },
        { label: (_) => "20%未満", value: "1" },
        { label: (_) => "10%未満", value: "2" },
    ],
};

export const questionInternalAuditor: Question = {
    id: "internalAuditor",
    scoreIndex: 15,
    label: '内部監査の',
    options: [
        {
            label: (phase) => {
                switch (phase) {
                    case Phase.n:
                    case Phase.n1:
                        return "担当者が居る";
                    case Phase.n2:
                    case Phase.n3:
                        return "これから採用する";
                }
            }, value: "0"
        },
        {
            label: (phase) => {
                switch (phase) {
                    case Phase.n:
                    case Phase.n1:
                        return "責任者が居る";
                    case Phase.n2:
                    case Phase.n3:
                        return "担当者が居る";
                }
            }, value: "1"
        },
        {
            label: (phase) => {
                switch (phase) {
                    case Phase.n:
                    case Phase.n1:
                        return "株主総会で質問があっても、J-SOX上の重要な勘定科目と業務プロセス、評価範囲、重要な統制点をほぼ諳んじることができる責任者が居る";
                    case Phase.n2:
                    case Phase.n3:
                        return "責任者が居る";
                }
            }, value: "2"
        },
    ],
};

export const questionCeo: Question = {
    id: 'ceo',
    scoreIndex: 16,
    label: '社長は',
    options: [
        { label: (_) => "常勤監査役の監査と内部監査の違いを自分の言葉で答えられる", value: "0" },
        { label: (_) => "内部監査に、独自に監査して欲しい事項を1つ以上依頼し、監査調書にそれが記録されている", value: "1" },
        { label: (_) => "内部監査に、部門毎に独自に監査して欲しい事項を1つ以上依頼し、監査調書にそれが記録されている", value: "2" },
    ]
};

export const questionBudget: Question = {
    id: "budget",
    scoreIndex: 17,
    label: "直近3ヶ月で",
    options: [
        { label: (_) => "売上高10%、利益項目30%以上の乖離が2か月以上ある", value: "0" },
        { label: (_) => "売上高10%、利益項目30%以上の乖離が1か月ある", value: "1" },
        { label: (_) => "売上高10%、利益項目30%未満の乖離", value: "2" },
    ],
};

export const questionMonthlyClosing: Question = {
    id: "monthlyClosing",
    scoreIndex: 18,
    label: "月次決算は減価償却計算含めて",
    options: [
        { label: (_) => "7営業日超かかる", value: "0" },
        { label: (_) => "7営業日内で締まる", value: "1" },
        { label: (_) => "5営業日で締まる", value: "2" },
    ],
};

export const questionApprover: Question = {
    id: "approver",
    scoreIndex: 19,
    label: "稟議の業務分掌は",
    options: [
        { label: (_) => "経理", value: "0" },
        { label: (_) => "経理と他部門の兼務", value: "1" },
        { label: (_) => "総務や経営企画など別部署", value: "2" },
    ],
};

export const questionTaxEffect: Question = {
    id: "taxEffect",
    scoreIndex: 20,
    label: "税効果について",
    options: [
        { label: (_) => "繰延税金資産と事業計画がどう連動しているか定量的に説明できそうな人材が1名以上いる", value: "1" },
        { label: (_) => "繰延税金資産と事業計画がどう連動しているか定量的に説明できそうな人材が2名以上いる", value: "2" },
        { label: (_) => "上記以外", value: "0" },
    ],
};

export const sections: Section[] = [
    {
        label: "",
        questions: [
            questionMarket,
            questionPhase,
        ],
    },
    {
        label: "業績",
        questions: [
            questionLatestPerformance
        ],
    },
    {
        label: "地域性",
        questions: [
            questionLocalReputation
        ],
    },
    {
        label: "取締役",
        questions: [
            questionDirectors,
        ],
    },
    {
        label: "監査役",
        questions: [
            questionAuditors
        ],
    },
    {
        label: "人事",
        questions: [
            questionHr,
        ],
    },
    {
        label: "採用",
        questions: [
            questionRecruit
        ],
    },
    {
        label: "情シス",
        questions: [
            questionInformationSystem,
            questionDeviceManagement,
            questionPenetrationTest,
            questionCustodyOfRecords,
        ],
    },
    {
        label: "法務",
        questions: [
            questionLaws,
            questionTerms,
        ],
    },
    {
        label: "労務",
        questions: [
            questionWorkingHoursDivergence,
            questionPcLogs,
            questionNightTime,
        ],
    },
    {
        label: "内部監査",
        questions: [
            questionInternalAuditor,
            questionCeo,
        ],
    },
    {
        label: "予算",
        questions: [
            questionBudget,
        ],
    },
    {
        label: "経理財務",
        questions: [
            questionMonthlyClosing,
            questionApprover,
            questionTaxEffect,
        ],
    },
];

export const questions: Question[] = Object.values(sections).map(s => s.questions).flat();

export const initialValues = Object.fromEntries(
    sections
        .map((s) => s.questions.map((q) => q.id))
        .flat()
        .map((id) => [id, ""])
);