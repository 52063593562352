import React, { useEffect } from "react";

type RankProps = {
  rank: string;
  setRank: (rank: string | undefined) => void;
};

const ranks: { [key: string]: string } = {
  A: "健全に近く、引き続き研鑽が期待されます",
  B: "この調子で一層の研鑽が期待されます",
  C: "努力が期待されます",
  D: "一層の努力が期待されます",
  E: "奮起が期待されます",
};

const styles: { [key: string]: React.CSSProperties } = {
  header: {
    paddingTop: 24,
    paddingBottom: 40,
    textAlign: "center",
  },
  h1: {
    fontWeight: 600,
    fontSize: 16,
  },
  rank: {
    fontWeight: 600,
    fontSize: 80,
  },
  table: {
    backgroundColor: "#ebeef4",
    padding: "16px 16px 5px",
    color: "#333",
  },
  tableHeading: {
    fontWeight: 600,
  },
  button: {
    fontSize: 16,
  },
  submitButton: {
    backgroundColor: "#2e4489",
    borderColor: "#2e4489",
  },
};

export const Rank = ({ rank, setRank }: RankProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div style={styles.header}>
        <div style={styles.h1}>判定結果</div>
        <div style={styles.rank}>{rank}</div>
        <div>{ranks[rank]}</div>
      </div>
      <div style={styles.table}>
        <div style={styles.tableHeading} className="mb-2">
          スコア表
        </div>
        {Object.keys(ranks).map((r) => (
          <div
            key={r}
            className="ml-2"
            style={{ textDecoration: r === rank ? "underline" : "none" }}
          >
            {r}： {ranks[r]}
          </div>
        ))}

        <p className="mt-3">
          ※現サンプルは2021年6月時点のものです。
          <br />
          NASDAQや海外市場は開発中のため、個別のご相談はお問い合わせよりご連絡下さい。
        </p>
      </div>

      <div className="row justify-content-md-center mt-4">
        <div className="col-md-8 col-lg-6">
          <button
            type="button"
            style={{ ...styles.button, ...styles.submitButton }}
            className="btn btn-primary btn-lg w-100"
            onClick={() => {
              window.open(
                "https://www.todorok-evolution.jp/#comp-kp1d8y802",
                "_blank"
              );
            }}
          >
            お問い合わせする
          </button>

          <button
            type="button"
            className="btn btn-outline-dark btn-lg w-100 mt-3"
            style={styles.button}
            onClick={() => {
              setRank(undefined);
            }}
          >
            戻ってやり直す
          </button>
        </div>
      </div>
    </>
  );
};
